import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import { useLocation, useParams } from "react-router-dom";

import {
  Badge,
  Box,
  Button,
  FlatList,
  HStack,
  Input,
  InputField,
  InputSlot,
  Pressable,
  Text,
  Image
} from "@gluestack-ui/themed";



import { BadgeText } from "@gluestack-ui/themed";
import FormatToBRL from "../helpers/FormatToBRL";
import GetMonth from "../helpers/GetMonth";

import useCompanyStorage from "../hooks/useCompanyStorage";
import useUniversalNavigation from "../hooks/useUniversalNavigation";
import urlConstruct from "../services/urlConstruct";

import { RefreshControl } from "@gluestack-ui/themed";
import LoadingPage from "../components/Loading/LoadingPage";
import ParseQueryParams from "../helpers/ParseQueryParams";
import api from "../services/api";
import FormatDate from "../helpers/FormatDate";
import GeneratePixQrcode from "../components/GeneratePixQrcode";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();
const isDateToday = (inputDate: string | number | Date) => new Date(inputDate).toDateString() === new Date().toDateString();
const isDatePast = (inputDate: string | number | Date) => new Date(inputDate) < new Date();
const isDatePending = (inputDate: string | number | Date) => {
  const monthFromNow = new Date(currentDate);
  monthFromNow.setDate(currentDate.getDate() + 30);
  return new Date(inputDate) > currentDate && new Date(inputDate) <= monthFromNow;
}

const BoletosPix = () => {
  const { cnpj } = useParams();
  const location = useLocation();
  const { company } = useCompanyStorage();

  const [boletos, setBoletos] = useState([]);
  const [filteredBoletos, setFilteredBoletos] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("todos");
  const [hasBankAccount, setHasBankAccount] = useState(false);
  const [month, setMonth] = useState(currentMonth.toString());
  const [year, setYear] = useState(currentYear.toString());
  const [filterVencidas, setFilterVencidas] = useState(false);

  useEffect(() => {
    if (Platform.OS === "web") {
      document.body.style.overflow = "auto";
      document.body.style.height = "100%";
    }
  }, []);

  const fetchBoletos = async () => {
    if (!company) return;
    try {
      setLoading(true);
      let params = { IdEmpresa: company?.company?.idEmpresa, Busca: '' };

      if (cnpj) {
        params.Busca += `Contato.DocIdentificacao.Replace(".","").Replace("-","").Replace("/","")=="${cnpj}"`;
        params.Busca += `&& DataVencimento.Month<=${month} && DataVencimento.Year<=${year} && Status == 0`;
      }
      const { lista } = await api.get(urlConstruct('/api/ContaReceber', params));
      setBoletos(lista);
      setFilteredBoletos(lista);
    } catch (error) {
      console.error(error)
      setBoletos([]);
      setFilteredBoletos([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBoletos();
  }, [company, cnpj, location]);

  const StatusText = (status: number, vencimento: string | number | Date) => {
    if (status === 1) return "Pago";
    if (status === 9) return "Cancelado";
    if (status === 0) {
      if (isDateToday(vencimento)) return "Vence hoje";
      if (isDatePast(vencimento)) return "Vencido";
      if (isDatePending(vencimento)) return "Pendente";
      return "Aberto";
    }
    return "Aberto";
  };

  const StatusBadge = ({ status, vencimento }) => {
    const text = StatusText(status, vencimento);

    const colorSchemes = {
      "Pago": "#dcfce7",
      "Pendente": "#fef9c3",
      "Vence hoje": "#ffb7b7",
      "Vencido": "#ffb7b7",
      "Cancelado": "#f4f4f5",
      "Aberto": "#dbeafe"
    };

    return (
      <Badge
        sx={{
          _dark: {
            backgroundColor: colorSchemes[text] || "#f4f4f5"
          },
          _light: {
            backgroundColor: colorSchemes[text] || "#f4f4f5"
          },
        }}>
        <BadgeText color="$black">{text}</BadgeText>
      </Badge>
    );
  };


  const onRefresh = async () => {
    setIsRefreshing(true);
    await fetchBoletos();
    setIsRefreshing(false);
  };

  const filtrarBoletosVencidos = (boletosArray) => {
    const dataAtual = Date.now();
    const boletosNaoPagos = boletos.filter(boleto => boleto.status === 0 && (boleto.idContaBancariaBoleto !== 'null' || boleto?.celcoinPixImagem !== "null"));
    const boletosVencidos = boletosNaoPagos.filter(boleto => Date.parse(boleto.dataVencimento) < dataAtual);
    return boletosVencidos;
  };

  if (loading) return (<LoadingPage color={'#01284b'} />);

  return (
    <>
      <Box
        flex={1}
        sx={{
          _light: { bg: "white" },
          _dark: { bg: "$backgroundDark950" },
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box
          flex={1}
          sx={{
            width: '100%',
            maxWidth: 500,
            overflow: 'scroll',
            flexGrow: 1
          }}
        >
          <Box
            flex={1} // Adicionando flex: 1 para ocupar todo o espaço disponível
            sx={{
              width: '100%',
              maxWidth: 500,
              maxHeight: '90%', // Defina uma altura máxima
              overflow: 'scroll',
            }}
          >
            <FlatList
              nestedScrollEnabled={true}
              scrollEventThrottle={16}
              data={filterVencidas ? filtrarBoletosVencidos(filteredBoletos) : filteredBoletos}
              refreshControl={
                <RefreshControl
                  refreshing={isRefreshing}
                  onRefresh={onRefresh}
                  title={isRefreshing ? 'Atualizando os boletos...' : 'Puxe para atualizar os boletos.'}
                />
              }
              renderItem={({ item: boleto }) => (
                <Box
                  sx={{
                    _light: { bg: "white" },
                    _dark: { bg: "$backgroundDark950" },
                  }}
                  $base-pl={0}
                  $base-pr={0}
                  $sm-pl="$4"
                  $sm-pr="$5"
                  py="$1"
                >
                  <Pressable
                    px={5}
                    mb="$1"
                    ml="$1"
                    mr="$1"
                    mt="$1"
                    borderRadius="$lg"
                    onPress={() => console.info("Clicou")}
                  >
                    <Box
                      sx={{
                        _light: { bg: "#ececec" },
                        _dark: { bg: "#252525" },
                        padding: 10
                      }}
                      rounded="$md"
                    >
                      <HStack
                        alignItems="center"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          ml="2px"
                        >
                          <Box>
                            <StatusBadge
                              status={boleto.status}
                              vencimento={boleto.dataVencimento}
                            />
                          </Box>
                          <Text mt="$2">{`Vencimento: ${FormatDate(boleto.dataVencimento)}`}</Text>
                          <Text mt="$2">{`Valor: R$ ${FormatToBRL(boleto.valorTotal)}`}</Text>
                        </Box>
                        <Box
                          mb="$-4"
                          display="flex"
                          flexDirection="column"
                        >
                          <GeneratePixQrcode qrcode={boleto?.celcoinPixImagem} contaId={boleto?.id} asaasId={boleto.asaasId} />
                        </Box>
                      </HStack>
                    </Box>
                  </Pressable>
                </Box>
              )}
              keyExtractor={(item) => item.id}
            />
          </Box>
        </Box>
      </Box>

    </>
  );
};

export default BoletosPix;
