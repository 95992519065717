import React from "react";
import { Platform, View, StatusBar } from 'react-native';
import { Route as RouteWeb, BrowserRouter as RouterWeb, Routes as RoutesWeb } from 'react-router-dom';
import { Route as RouteNative, NativeRouter as RouterNative, Routes as RoutesNative } from 'react-router-native';
import Boletos from "../screens/Boletos";
import BoletosPix from "../screens/BoletosPix";
import HomePage from '../screens/HomePage';
import Login from "../screens/Login";
import Layout from "../Layout/Layout";
import BoletoDetalhes from "../screens/BoletoDetalhes";

const routes = [
    { element: HomePage, path: '/' },
    { element: Boletos, path: '/boletos' },
    { element: BoletosPix, path: '/boletos_pix' },
    { element: BoletoDetalhes, path: '/b/detalhes' }
];

// Seleciona os componentes de Route e Routes baseados na plataforma
const Route = Platform.select({
    web: RouteWeb,
    default: RouteNative,
});

const Routes = Platform.select({
    web: RoutesWeb,
    default: RoutesNative,
});

const Router = Platform.select({
    web: RouterWeb,
    default: RouterNative,
});

const AppRoutes = () => {

    return (
        <Router>
            <Routes>
                <Route path={`/:tenantId?`} element={<Login />} />
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={`/:tenantId/:cnpj${route.path}`}
                        element={
                            <Layout>
                                <route.element />
                            </Layout>
                        }
                    />
                ))}
            </Routes>
        </Router>
    );
};

export default AppRoutes;
