;

import React, { useEffect, useState, useContext } from "react";
import { Image, Platform, Linking } from "react-native";

import { useLocation, useParams } from "react-router-dom";

import AsyncStorage from '@react-native-async-storage/async-storage';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import ParseQueryParams from "../helpers/ParseQueryParams";
import useCompanyStorage from "../hooks/useCompanyStorage";
import useUniversalNavigation from "../hooks/useUniversalNavigation";

import { Badge, BadgeText, Box, Button, HStack, Heading, Text, VStack } from "@gluestack-ui/themed";

import { Divider } from "@gluestack-ui/themed";
import AlertError from "../components/alerts/AlertError";
import AlertInfo from "../components/alerts/AlertInfo";
import AlertSuccess from "../components/alerts/AlertSuccess";
import AlertWarning from "../components/alerts/AlertWarning";

import { ButtonText, Card } from "@gluestack-ui/themed";
import FormatToBRL from "../helpers/FormatToBRL";
import api from "../services/api";
import html2pdf from 'html2pdf.js';

import * as Clipboard from 'expo-clipboard';
import * as FileSystem from 'expo-file-system';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import { ToastContext } from "../hooks/useToast";

import axios from 'axios';
import urlConstruct from "../services/urlConstruct";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();
const isDateToday = (inputDate: string | number | Date) => new Date(inputDate).toDateString() === new Date().toDateString();
const isDatePast = (inputDate: string | number | Date) => new Date(inputDate) < new Date();
const isDatePending = (inputDate: string | number | Date) => {
  const monthFromNow = new Date(currentDate);
  monthFromNow.setDate(currentDate.getDate() + 30);
  return new Date(inputDate) > currentDate && new Date(inputDate) <= monthFromNow;
}

function compararDataComHoje(dataString) {
  if (isDateToday(dataString)) return "hoje";
  if (isDatePast(dataString)) return "vencido";
  if (isDatePending(dataString)) return "pendente";
  return "aberto";
}


function getCodeFromHtml(htmlString) {
  const startTag = '<td class="w450 Ar ld" valign="bottom">';
  const endTag = '</td>';
  let start = htmlString.indexOf(startTag);
  if (start === -1) return null;
  start += startTag.length;
  const end = htmlString.indexOf(endTag, start);
  if (end === -1) return null;
  let code = htmlString.slice(start, end);
  code = code.replace(/[^a-zA-Z0-9]/g, ' ');
  return code;
}


const BoletoDetalhes = () => {

  const { navigate } = useUniversalNavigation();
  const toast = useContext(ToastContext);

  const location = useLocation();
  const { company } = useCompanyStorage();
  const { tenantId, cnpj } = useParams();

  const [boleto, setBoleto] = useState(null);
  const [loading, setLoading] = useState(false);

  const [clickedButton, setClickedButton] = useState(null);

  useEffect(() => {
    if (Platform.OS === "web") {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const queryParams = ParseQueryParams(location.search);

        let params = {
          IdEmpresa: queryParams.IdBoleto,
          Busca: `Id = "${queryParams.IdBoleto}"`
        };

        const { lista } = await api.get(urlConstruct('/api/ContaReceber', params));

        setBoleto(lista[0]);

      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false);
      }
    })();
  }, [location.search])

  async function gerarPdfBoleto(boletoId, name, isCopy) {
    setLoading(true);
    try {
      const data = await api.post(`/api/ContaReceber/gerarBoletoApp/${boletoId}?idEmpresa=${company?.company?.idEmpresa}`);
      if (isCopy) {
        setClickedButton('copy');
        const codigoDoBoleto = getCodeFromHtml(data);
        await Clipboard.setStringAsync(codigoDoBoleto);
        toast.success({ title: 'Sucesso!', description: 'Código do boleto copiado para a área de transferência.' });
      } else {
        setClickedButton('pdf');
        if (Platform.OS === 'web') {
          const element = document.createElement('div');
          element.innerHTML = data;
          html2pdf().from(element).set({
            margin: [0.5, 0.5, 0.5, 0.5], // Margens em polegadas
            filename: `boleto_${boletoId}.pdf`,
            image: { type: 'jpeg', quality: 1.0 }, // Qualidade da imagem
            html2canvas: { scale: 3 }, // Aumenta a escala do HTML2Canvas para melhor qualidade
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' } // Formato e orientação do PDF
          }).save();
        } else {
          const { uri, base64 } = await Print.printToFileAsync({ html: data, base64: true });
          const sanitizedFileName = name.replace(/[^a-zA-Z0-9]/g, '_');
          const pdfName = `${sanitizedFileName}.pdf`;
          const newUri = FileSystem.documentDirectory + pdfName;
          const fileInfo = await FileSystem.getInfoAsync(newUri);
          if (fileInfo.exists) await FileSystem.deleteAsync(newUri);
          await FileSystem.writeAsStringAsync(newUri, base64, { encoding: FileSystem.EncodingType.Base64 });
          if (!(await Sharing.isAvailableAsync())) toast.error({ title: 'Erro!', description: 'A partilha não está disponível na sua plataforma.' });
          await Sharing.shareAsync(newUri);
        }

      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
      setClickedButton(null);
    }
  }

  async function generateFile(fileData) {
    try {
      const getCompany = await AsyncStorage.getItem('company');
      const { tenantId } = JSON.parse(getCompany);

      const fileUrl = `https://gestor.unitechsistemas.com/${tenantId}/${fileData.diretorio}/${fileData.nome}${fileData.extensao}`;

      // Abre o link no navegador padrão do dispositivo
      await Linking.openURL(fileUrl);
    } catch (error) {
      console.error('Erro ao abrir o arquivo no navegador:', error);
    }
  };

  function StatusBadge({ status, vencimento }) {
    switch (status) {
      case 1:
      case 1:
        return <Badge size="md" variant="solid" borderRadius="$xs" action="success"><BadgeText>Pago</BadgeText></Badge>;
      case '0':
      case 0:
        if (!vencimento) {
          return <Badge size="md" variant="solid" borderRadius="$xs" action="warning"><BadgeText>Pendente</BadgeText></Badge>;
        } else {
          switch (compararDataComHoje(vencimento)) {
            case 'aberto':
              return <Badge size="md" variant="solid" borderRadius="$xs" action="info"><BadgeText>Aberto</BadgeText></Badge>;
            case 'hoje':
              return <Badge size="md" variant="solid" borderRadius="$xs" action="error"><BadgeText>Vence hoje</BadgeText></Badge>;
            case 'vencido':
              return <Badge size="md" variant="solid" borderRadius="$xs" action="error"><BadgeText>Vencido</BadgeText></Badge>;
            default:
              return <Badge size="md" variant="solid" borderRadius="$xs" action="warning"><BadgeText>Pendente</BadgeText></Badge>;
          }
        }
      case '9':
      case 9:
        return <Badge size="md" variant="solid" borderRadius="$xs" action="error"><BadgeText>Cancelado</BadgeText></Badge>;
      default:
        return <Badge size="md" variant="solid" borderRadius="$xs" action="muted"><BadgeText>Futuro</BadgeText></Badge>;
    }
  }

  function RenderAlert({ status, vencimento, emitido }) {

    switch (status) {
      case 1:
        if (emitido) {
          return <AlertSuccess title="O boleto foi pago!" description="Estes dados agora existem apenas para visualização." />;
        } else {
          return <AlertInfo title="O boleto ainda não foi emitido!" description="Aguarde ou entre em contato com o emissor." />;
        }
      case 0:
        if (!vencimento) {
          return <AlertWarning title="O boleto foi emitido, mas ainda está pendente!" description="Por favor, verifique novamente mais tarde." />;
        } else {

          switch (compararDataComHoje(vencimento)) {
            case 'aberto':
              if (emitido) {
                return <AlertInfo title="O boleto esta pronto!" description="Este boleto já está pronto para ser pago." />;
              } else {
                return <AlertInfo title="O boleto ainda não foi emitido!" description="Aguarde ou entre em contato com o emissor." />;
              }
            case 'hoje':
              return <AlertError title="O boleto vence hoje!" description="Por favor, efetue o pagamento imediatamente para evitar juros." />;
            case 'pendente':
              return <AlertError title="Aguardando pagamento!" description="Este boleto já está pronto para ser pago." />;
            default:
              return <AlertWarning title="O boleto já venceu!" description="Evite juros e problemas futuros efetuando o pagamento o quanto antes." />;
          }
        }
      case 9:
        return <AlertError title="O boleto foi cancelado!" description="Por favor, entre em contato com o emissor para mais informações." />;
      default:
        return <AlertInfo title="Status do boleto desconhecido!" description="Por favor, verifique novamente mais tarde." />;
    }
  }

  const emitido = boleto?.idContaBancariaBoleto != null || boleto?.valor === boleto?.valorPago;
  const arquivoGerado = boleto?.arquivoAnexo != null;

  if (loading || boleto == null) return (
    <>
      <Text>Loading</Text>
    </>
  );

  return (
    <>
      <Box
        flex={1}
        sx={{
          _light: { bg: "white" },
          _dark: { bg: "$backgroundDark950" },
          overflow: 'scroll',
          flexGrow: 1
        }}
      >
        <Box flex={1}
          sx={{
            marginTop: '$-1px',
            width: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box flex={1}
            alignItems="center"
            sx={{
              width: '100%',
              maxWidth: 500,
            }}
          >
            <Card p="$5" borderRadius="$lg" maxWidth={400} m="$3">
              <Heading size="md" fontFamily="$heading" mb="$4">
                Detalhes do boleto
              </Heading>
              <RenderAlert status={boleto.status} vencimento={boleto.dataVencimento} emitido={emitido} />
              <VStack mb="$6">
                <Divider sx={{ marginTop: 20, marginBottom: 20 }} />
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    marginTop: 10,
                    marginBottom: 15
                  }}
                >
                  <StatusBadge status={boleto.status} vencimento={boleto.dataVencimento} />
                  {boleto?.parcela && <Text>Parcela: {boleto.parcela}</Text>}
                </HStack>
                <VStack space="md">
                  <HStack justifyContent="space-between">
                    <Text >Beneficiário:</Text>
                    <Text sx={{ fontSize: 13 }}>{boleto.contatoNome}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >Documento:</Text>
                    <Text fontWeight="medium">{boleto.contatoDocIdentificacao}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >Descrição:</Text>
                    <Text fontWeight="medium">{boleto.descricao}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >Telefone:</Text>
                    <Text fontWeight="medium">{boleto.telefonePrincipal}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >E-mail:</Text>
                    <Text fontWeight="medium">{boleto.emailPrincipal}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >Valor Total:</Text>
                    <Text fontWeight="medium">R$ {FormatToBRL(boleto.valorTotal)}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >Data de Vencimento:</Text>
                    <Text fontWeight="medium">{format(new Date(boleto.dataVencimento), 'dd/MMMM/yyyy', { locale: ptBR })}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >Juros Previstos:</Text>
                    <Text fontWeight="medium">R$ {FormatToBRL(boleto.jurosPrevisao)}</Text>
                  </HStack>
                  <HStack justifyContent="space-between">
                    <Text >Multa Prevista:</Text>
                    <Text fontWeight="medium">R$ {FormatToBRL(boleto.multaPrevisao)}</Text>
                  </HStack>
                  {boleto?.desconto != 'null' && < HStack justifyContent="space-between">
                    <Text >Desconto:</Text>
                    <Text fontWeight="medium">{boleto.desconto}</Text>
                  </HStack>}
                </VStack>
              </VStack>

              {+boleto?.status != 1 && (
                <React.Fragment>
                  <React.Fragment>
                    <Divider sx={{ marginTop: 20, marginBottom: 20 }} />
                    {boleto?.celcoinPixImagem != null ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Image
                          source={boleto.celcoinPixImagem}
                          alt="logo"
                          style={{ width: 250, height: 250 }}
                        />
                        <Button
                          onPress={() => { Clipboard.setStringAsync(boleto.celcoinPixCopiaCola); toast.success({ title: 'Sucesso!', description: 'Código do PIX copiado para a área de transferência.' }); }}
                          px="$4"
                          py="$2"
                          fontFamily="$heading"
                          mr="$0"
                          mb="$3"
                          sx={{
                            margin: '5px 0px',
                            width: '100%',
                            '@sm': {
                              mr: '$3',
                              mb: '$0',
                              flex: 1,
                            },
                          }}
                        >
                          <ButtonText size="sm">Copiar codigo PIX</ButtonText>
                        </Button>
                        <Divider sx={{ marginTop: 20, marginBottom: 20 }} />
                        <Button
                          onPress={() => { Clipboard.setStringAsync(boleto.celcoinLinhaDigitavel); toast.success({ title: 'Sucesso!', description: 'Código do boleto copiado para a área de transferência.' }); }}
                          px="$4"
                          py="$2"
                          variant="outline"
                          fontFamily="$heading"
                          borderColor="$borderLight300"
                          $dark-borderColor="$backgroundDark600"
                          sx={{
                            width: '100%',
                            margin: '5px 0px',
                            '@sm': {
                              mr: '$3',
                              mb: '$0',
                              flex: 1,
                            },
                          }}
                        >
                          <ButtonText
                            size="sm"
                            color="$textLight600"
                            $dark-color="$textDark400"
                          >
                            Copiar linha digitável
                          </ButtonText>
                        </Button>
                        <Button
                          onPress={() => { Linking.openURL(boleto.celcoinLinkPDF); }}
                          px="$4"
                          py="$2"
                          variant="outline"
                          fontFamily="$heading"
                          borderColor="$borderLight300"
                          $dark-borderColor="$backgroundDark600"
                          sx={{
                            width: '100%',
                            margin: '5px 0px',
                            '@sm': {
                              mr: '$3',
                              mb: '$0',
                              flex: 1,
                            },
                          }}
                        >
                          <ButtonText
                            size="sm"
                            color="$textLight600"
                            $dark-color="$textDark400"
                          >
                            Abrir boleto PDF
                          </ButtonText>
                        </Button>
                        <Divider sx={{ marginTop: 20, marginBottom: 20 }} />
                      </Box>
                    ) : (
                      <Box
                        flexDirection="column"
                        sx={{
                          '@sm': {
                            flexDirection: 'row',
                          },
                        }}
                      >
                        {boleto.status === 1 && !boleto?.celcoinPixImagem && emitido ? null : (
                          <React.Fragment>
                            {boleto.status != 9 && <Button
                              isDisabled={(loading && clickedButton === 'pdf') || loading || !emitido}
                              onPress={() => gerarPdfBoleto(boleto.id, boleto.contatoNome, false)}
                              px="$4"
                              py="$2"
                              fontFamily="$heading"
                              mr="$0"
                              mb="$3"
                              sx={{
                                '@sm': {
                                  mr: '$3',
                                  mb: '$0',
                                  flex: 1,
                                },
                              }}
                            >
                              <ButtonText size="sm"> {emitido ? 'Gerar PDF' : 'Boleto indisponivel.'}</ButtonText>
                            </Button>
                            }

                            {emitido && (
                              <Button
                                isDisabled={(loading && clickedButton === 'copy') || loading || !emitido}
                                onPress={() => { gerarPdfBoleto(boleto.id, boleto.contatoNome, true); }}
                                px="$4"
                                py="$2"
                                variant="outline"
                                fontFamily="$heading"
                                borderColor="$borderLight300"
                                $dark-borderColor="$backgroundDark600"
                                sx={{
                                  '@sm': {
                                    flex: 1,
                                  },
                                }}
                              >
                                <ButtonText
                                  size="sm"
                                  color="$textLight600"
                                  $dark-color="$textDark400"
                                >
                                  Copiar Linha digitavel
                                </ButtonText>
                              </Button>
                            )}
                          </React.Fragment>
                        )}
                      </Box>
                    )}
                  </React.Fragment>
                  {arquivoGerado && <React.Fragment>
                    <Button
                      onPress={() => { generateFile(boleto.arquivoAnexo) }}
                      px="$4"
                      py="$2"
                      variant="outline"
                      fontFamily="$heading"
                      borderColor="$borderLight300"
                      sx={{
                        marginTop: 2,
                        width: '100%',
                        '@sm': {
                          mr: '$3',
                          mb: '$0',
                          flex: 1,
                        },
                      }}
                    >
                      <ButtonText
                        size="sm"
                        color="$textLight600"
                        $dark-color="$textDark400"
                      >
                        Baixar Nota
                      </ButtonText>
                    </Button>
                  </React.Fragment>}
                </React.Fragment>
              )}
            </Card>
          </Box>
        </Box >
      </Box >
    </>
  );
};
export default BoletoDetalhes;
