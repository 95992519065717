import { config as defaultConfig } from "@gluestack-ui/config";

export const config = {
  ...defaultConfig,
  tokens: {
    ...defaultConfig.tokens,
    colors: {
      ...defaultConfig.tokens.colors,
      primary0: "#ffffff",
      primary50: "#01284b",
      primary100: "#01284b",
      primary200: "#01284b",
      primary300: "#01284b",
      primary400: "#01284b",
      primary500: "#01284b",
      primary600: "#01284b",
      primary600_alpha60: "#01284b",
      primary700: "#01284b",
      primary800: "#01284b",
      primary900: "#01284b",
      primary950: "#01284b",
    },
  } as const,
};

type Config = typeof config;
declare module "@gluestack-ui/config" {
  interface IConfig extends Config {}
}
