import {
  Box,
  HStack
} from "@gluestack-ui/themed";
import React from "react";

import HeaderTabs from "./header/HeaderTabs";
import UniBoletosLogo from "./header/UniBoletosLogo";
import ToggleMode from "./header/ToggleMode";
import UserProfile from "./header/UserProfile";

const Header = React.memo(({ useHeader }) => {
  console.info({ useHeader })
  return (
    <React.Fragment>
      <Box
        px="$16"
        w="100%"
        borderBottomWidth={1}
        display="none"
        sx={{
          "@md": {
            display: "flex",
          },
          _light: { borderColor: "$borderLight100" },
          _dark: { borderColor: "$borderDark900" },
        }}
      >
        <HStack
          alignItems="center"
          justifyContent="space-between"
          mx="auto"
          w="100%"
        >
          {useHeader && (
            <>
              <Box
                sx={{
                  height: '55px',
                  display: "flex",
                  flexDirection: 'row',
                  alignItems: 'center',
                  _light: { borderColor: "$borderLight100" },
                  _dark: { borderColor: "$borderDark900" },
                }}>
                <UniBoletosLogo />
                <HeaderTabs />
              </Box>

              <HStack space="lg" alignItems="center" pr="$1.5">
                <ToggleMode />
                <UserProfile />
              </HStack>
            </>
          )}
        </HStack>
      </Box>
      {/* small screen */}
      <Box
        p="$5"
        display="none"
        // sx={{
        //   "@md": {
        //     display: "none",
        //   },
        // }}
        w="100%"
      >
      </Box>
    </React.Fragment >
  );
});
export default Header;
