export default function urlConstruct(base, parametros) {
    let url = base;

    const parametrosArray = [];
    for (let chave in parametros) {
        if (parametros.hasOwnProperty(chave) && parametros[chave] !== undefined && parametros[chave] !== null) {
            parametrosArray.push(`${chave}=${encodeURIComponent(parametros[chave])}`);
        }
    }

    if (parametrosArray.length) {
        url += '?' + parametrosArray.join('&');
    }

    return url.replace(/\\/g, '');
}
