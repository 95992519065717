import React from 'react';
import { SelectList } from 'react-native-dropdown-select-list'

const SearchSelect = ({ data, value, placeholder, searchPlaceholder, notFoundText, onSelect }) => {

    function findByKey(array, keyToFind) {
        return array.find(item => item.key === keyToFind);
    }

    return (
        <SelectList
            disabled={true}
            inputStyles={{
                //   height: '40px',
                padding: "5px"
            }}
            boxStyles={{
                borderColor: '#01284b',
                padding: "5px",
                borderRadius: 6
            }}
            dropdownStyles={{ borderColor: '#01284b' }}
            setSelected={onSelect}
            placeholder={placeholder}
            searchPlaceholder={searchPlaceholder}
            notFoundText={notFoundText}
            data={data}
            defaultOption={findByKey(data, value)}
        />
    )

};

export default SearchSelect;