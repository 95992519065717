import {
  Button,
  ButtonText,
  Heading,
  VStack
} from "@gluestack-ui/themed";
import React from "react";
import { useParams } from "react-router-dom";
import useUniversalNavigation from "../../hooks/useUniversalNavigation";

const MenuItemButton = ({ item, onClickNavigate }: any) => {
  return (
    <Button
      variant="outline"
      disabled={item.disabled}
      onPress={() => { onClickNavigate(`${item.router}`) }}
    >
      <ButtonText>{item.label}</ButtonText>
    </Button>
  );
};

const MenuItemsSection = () => {

  const { navigate } = useUniversalNavigation();

  const { tenantId, cnpj } = useParams();

  const MenuItemsList = [
    {
      label: "Inicio",
      router: ''
    },
    {
      label: "Boletos",
      router: `/boletos`
    },
    {
      label: "Sobre",
      router: "/info",
      disabled: true
    }
  ];

  return (
    <VStack space="sm">
      <Heading size="sm">Menu</Heading>
      {MenuItemsList.map((MenuItem) => {
        return (
          <MenuItemButton item={MenuItem} onClickNavigate={navigate} />
        );
      })}
    </VStack>
  );
};
export default MenuItemsSection;
