import { Platform } from 'react-native';
import { useNavigate as useNavigateWeb } from 'react-router-dom';
import { useNavigate as useHistoryNative } from 'react-router-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const useUniversalNavigation = () => {
    const navigateWithParams = useNavigateWeb ? useNavigateWeb() : useHistoryNative();

    const navigate = async (path) => {
        const storedCompany = await AsyncStorage.getItem('company');
        const company = JSON.parse(storedCompany);
        // Construct the complete path with tenantId, cnpj, and route.path
        const completePath = !company?.tenantId ? `${path}` : `/${company.tenantId}/${company.cnpj}${path}`;
        navigateWithParams(completePath);
    };

    return { navigate };
};

export default useUniversalNavigation;
