import React from "react";
import { Image } from 'react-native';
import {
  Box,
  Text
} from "@gluestack-ui/themed";

const UniBoletosLogo = () => {
  return (
    <Box>
      <Image
        source={require('../../../assets/logo_full.png')}
        alt="logo"
        style={{ width: 130, height: 40, resizeMode: 'contain' }}
      />
    </Box>
  );
};

export default UniBoletosLogo;
