import React from "react";
import { HStack, Icon, Pressable, Text, VStack } from "@gluestack-ui/themed";
import ListYourPlaceModal from "./main-content/ListYourPlaceModal";
import MobileSidebarActionsheet from "./MobileSidebarActionsheet";
import { Platform } from "react-native";

const MobileBottomTabs = ({ bottomTabs, activeTab, setActiveTab, onClickNavigate }: any) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [actionsheetVisible, setActionsheetVisible] = React.useState(false);

  return (
    <>
      <HStack
        alignContent="center"
        position="absolute"
        bottom={0}
        justifyContent="space-between"
        w="100%"
        py="$3"
        px="$6"
        sx={{
          backgroundColor: '#01284b',
          "@md": { display: "none" },
          marginBottom: Platform.OS === "web" ? 0 : 20
        }}
      >
        {bottomTabs.map((tab: any) => {
          return (
            <Pressable
              key={tab.label}
              onPress={() => {
                setActiveTab(tab.label);
                onClickNavigate(`${tab.router}`)
              }}
              disabled={tab.disabled}
              opacity={tab.disabled ? 0.3 : 1}
            >
              <VStack alignItems="center">
                <Icon
                  as={tab.icon}
                  color={
                    activeTab === tab.label ? "$primary500" : "$textLight400"
                  }
                  sx={{
                    color:
                      activeTab === tab.label
                        ? "$textDark100"
                        : "$secondary300"
                  }}
                  size='lg'
                />
                <Text
                  importantForAccessibility="no"
                  size="xs"
                  color={
                    activeTab === tab.label ? "$textLight900" : "$textLight400"
                  }
                  sx={{
                    color: activeTab === tab.label
                      ? "$textDark100"
                      : "$textLight400",
                  }}
                >
                  {tab.label}
                </Text>
              </VStack>
            </Pressable>
          );
        })}
      </HStack >
      {modalVisible && (
        <ListYourPlaceModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )
      }
      {
        actionsheetVisible && (
          <MobileSidebarActionsheet
            actionsheetVisible={actionsheetVisible}
            setActionsheetVisible={setActionsheetVisible}
          />
        )
      }
    </>
  );
};

export default MobileBottomTabs;
