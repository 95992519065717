import axios, { AxiosRequestConfig } from 'axios';

const ObterPixAsaas = async (contaId) => {
    try {
        const response = await axios.get(`https://gestor.unitechsistemas.com/financeiro/api/gerenciamentounitech/obterPixAsaas`, {
            params: {
                api_key: 'gestor_unicheff_ee046272_6f38_4f51_9d12_ef8152a228e1',
                idConta: contaId,
            },
            maxBodyLength: Infinity,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching PIX data:', error);
        return null;
    }
}

export default ObterPixAsaas;
