import { Alert, HStack, Text, VStack } from "@gluestack-ui/themed";
import React from "react";

const ToastSuccess = ({
    id,
    status,
    variant = "accent",
    title,
    description,
    isClosable,
    ...rest
}) => <Alert maxWidth="100%" alignSelf="center" flexDirection="row" action="success" m="5" variant={variant} {...rest}>
        <VStack space={1} flexShrink={1} w="100%">
            <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
                <HStack space={2} flexShrink={1} alignItems="center">
                    <Alert.Icon />
                    <Text fontSize="md" fontWeight="medium" flexShrink={1} color={variant === "solid" ? "lightText" : variant !== "outline" ? "darkText" : null}>
                        {title}
                    </Text>
                </HStack>
            </HStack>
            <Text px="6" fontSize="sm" color={variant === "solid" ? "lightText" : variant !== "outline" ? "darkText" : null}>
                {description}
            </Text>
        </VStack>
    </Alert>;


export default ToastSuccess;