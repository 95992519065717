import React, { createContext, useContext } from 'react';
import { useToast } from "@gluestack-ui/themed";
import ToastError from './error';
import ToastInfo from './info';
import ToastSuccess from './success';
import ToastWarn from './warn';

export const ToastContext = createContext(null);

const useAllToasts = () => {
    const toast = useToast();

    const showToast = ({ component: Component, title, description, isClosable = true, status }) => {
        toast.show({
            duration: 2000,
            placement: "top",
            render: () => <Component title={title} variant="solid" description={description} isClosable={isClosable} status={status} />
        });
    };

    const info = (options) => showToast({ ...options, component: ToastInfo, status: "info" });
    const warn = (options) => showToast({ ...options, component: ToastWarn, status: "warning" });
    const error = (options) => showToast({ ...options, component: ToastError, status: "error" });
    const success = (options) => showToast({ ...options, component: ToastSuccess, status: "success" });

    return { info, warn, error, success };
};

export const ToastProvider = ({ children }) => {
    const toasts = useAllToasts();

    return (
        <ToastContext.Provider value={toasts}>
            {children}
        </ToastContext.Provider>
    );
};
