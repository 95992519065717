import React from 'react';
import { View, StyleSheet } from 'react-native';
import { GluestackUIProvider, Spinner } from '@gluestack-ui/themed';

const LoadingPage = ({ color = "#fff" }) => {
    return (
        <GluestackUIProvider>
            <View style={[styles.loading, { backgroundColor: color }]}>
                <Spinner size="large" />
            </View>
        </GluestackUIProvider>
    );
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
    },
});

export default LoadingPage;
