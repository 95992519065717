const ParseQueryParams = (queryString) => {
    const params = {};
    const query = queryString.substring(1);
    const pairs = query.split('&');
    pairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });
    return params;
};

export default ParseQueryParams;
