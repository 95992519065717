import React, { useState } from "react";
import {
  Avatar,
  AvatarBadge,
  AvatarFallbackText,
  AvatarImage,
  Menu,
  MenuItem,
  MenuItemLabel,
  Pressable,
} from "@gluestack-ui/themed";

import useCompanyStorage from "../../hooks/useCompanyStorage";
import LogoutAlertDialog from "../LogoutAlertDialog";
import useUniversalNavigation from "../../hooks/useUniversalNavigation";

const UserProfile = () => {

  const { navigate } = useUniversalNavigation();

  const { removeCompany } = useCompanyStorage()

  const userMenuItems = [
    {
      title: "Sair",
      onClick: async () => {
        await removeCompany();
        navigate('/')
      }
    },
  ];

  const [openLogoutAlertDialog, setOpenLogoutAlertDialog] = useState(false);

  return (
    <>
      <Menu
        offset={10}
        placement="bottom right"
        selectionMode="single"
        // @ts-ignore
        onSelectionChange={(e: any) => {
          if (e.currentKey === "Log out") {
            setOpenLogoutAlertDialog(true);
          }
        }}
        trigger={({ ...triggerProps }) => {
          return (
            <Pressable {...triggerProps}>
              <Avatar size="sm" bg="$backgroundLight600">
                <AvatarFallbackText>UniBoletos</AvatarFallbackText>
                <AvatarImage
                  source={{
                    uri: require('../../../assets/logo.png'),
                  }}
                  alt="avatar"
                />
                <AvatarBadge
                  bg="$primary500"
                  sx={{
                    _dark: {
                      borderColor: "$backgroundDark900",
                    },
                  }}
                />
              </Avatar>
            </Pressable>
          );
        }}
      >
        {userMenuItems.map((item) => (
          <MenuItem key={item.title} onPress={item.onClick} textValue={item.title}>
            <MenuItemLabel>{item.title}</MenuItemLabel>
          </MenuItem>
        ))}
      </Menu>
      <LogoutAlertDialog
        openLogoutAlertDialog={openLogoutAlertDialog}
        setOpenLogoutAlertDialog={setOpenLogoutAlertDialog}
      />
    </>
  );
};

export default UserProfile;
