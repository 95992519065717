import { Box, Text } from "@gluestack-ui/themed";

import React, { useEffect } from "react";
import { Platform } from "react-native";

import { useParams } from "react-router-dom";
import HomeContent from "../components/HomeContent/HomeContent";
import useCompanyStorage from "../hooks/useCompanyStorage";
import useUniversalNavigation from "../hooks/useUniversalNavigation";

const HomePage = () => {

  const { navigate } = useUniversalNavigation();
  const { company } = useCompanyStorage();
  const { tenantId, cnpj } = useParams();

  useEffect(() => {
    if (Platform.OS === "web") {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
  }, []);


  return (
    <>
      <Box
        flex={1}
        sx={{
          _light: { bg: "white" },
          _dark: { bg: "$backgroundDark950" },
        }}
      >
        <Box flex={1}>
          <HomeContent data={company} />
        </Box>
      </Box>
    </>
  );
};
export default HomePage;
