import React from "react";
import { Alert, VStack, HStack, Box, Heading, AlertIcon, Text } from "@gluestack-ui/themed";
import { TriangleAlert } from "lucide-react-native";

const AlertWarning = ({ title, description }) => {
    return (<Alert shadow={2} maxW="400" w="100%" colorScheme="warning">
        <VStack space={1} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                <HStack space={2} flexShrink={1} alignItems="center">
                    <AlertIcon as={TriangleAlert} mr="$3" />
                    <Heading sx={{ fontSize: '20px' }}  _dark={{ _text: { color: "coolGray.600" } }}>
                        {title}
                    </Heading>
                </HStack>
            </HStack>
            <Box pl="6" _dark={{ _text: { color: "coolGray.600" } }}>
                <Text>{description}</Text>
            </Box>
        </VStack>
    </Alert>
    )
}

export default AlertWarning;