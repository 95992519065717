import AsyncStorage from '@react-native-async-storage/async-storage';
import axios, { AxiosRequestConfig } from 'axios';

function extractErrorMessage(data: string): string {
    const prefix = "System.Exception: ";
    const suffix = "at Service.";

    const start = data.indexOf(prefix);
    const end = data.indexOf(suffix);

    if (start !== -1 && end !== -1) {
        return data.substring(start + prefix.length, end).trim();
    }
    return data; // return full data if the pattern isn't found
}

async function request(endpoint: string, method: string = 'GET', data?: any = null): Promise<any> {

    const getCompany = await AsyncStorage.getItem('company');
    const { tenantId } = JSON.parse(getCompany);

    const config: AxiosRequestConfig = {
        method,
        url: `${'https://api.gestor.unitechsistemas.com'}/${tenantId}${endpoint}`,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (data) {
        config.data = data;
    }

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            const extractedMessage = extractErrorMessage(error.response.data);
            return extractedMessage;
        } else {
            return `Ocorreu um erro ao tentar realizar a requisição: ${JSON.stringify(error)}`;
        }
    }
}

const api = {
    get: async (endpoint: string): Promise<any> => {
        return await request(endpoint);
    },
    post: async (endpoint: string, data?: any): Promise<any> => {
        return await request(endpoint, 'POST', data);
    },
    put: async (endpoint: string, data?: any): Promise<any> => {
        return await request(endpoint, 'PUT', data);
    },
    delete: async (endpoint: string): Promise<any> => {
        return await request(endpoint, 'DELETE');
    }
};

export default api;
