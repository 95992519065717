import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useUniversalNavigation from './useUniversalNavigation';

const useCompanyStorage = () => {

  const { navigate } = useUniversalNavigation();
  const [company, setCompany] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadCompany = async () => {
      setLoading(true)
      try {
        const storedCompany = await AsyncStorage.getItem('company');
        if (storedCompany) {
          setCompany(JSON.parse(storedCompany));
          setIsAuth(true)
        } else {
          setIsAuth(false)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false);
      }
    };
    loadCompany();
  }, []);

  const saveCompany = async (updatedCompany) => {
    setLoading(true);
    try {
      await AsyncStorage.setItem('company', JSON.stringify(updatedCompany));
      setCompany(updatedCompany);
      setIsAuth(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  };

  const removeCompany = async () => {
    setLoading(true);
    try {
      await AsyncStorage.removeItem('company');
      setCompany(null);
      setIsAuth(false);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
      navigate('/')
    }
  };

  return {
    isAuth,
    loading,
    company,
    saveCompany,
    removeCompany,
    setCompany
  };
};

export default useCompanyStorage;
