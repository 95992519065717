import {
    Box,
    Button,
    ButtonText,
    Center,
    CheckIcon,
    Checkbox,
    CheckboxIcon,
    CheckboxLabel,
    ChevronDownIcon,
    CloseIcon,
    FormControl,
    FormControlLabel,
    FormControlLabelText,
    HStack,
    Heading,
    Icon,
    Modal,
    ModalBackdrop,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Select,
    SelectBackdrop,
    SelectContent,
    SelectDragIndicator,
    SelectDragIndicatorWrapper,
    SelectIcon,
    SelectInput,
    SelectPortal,
    SelectTrigger,
    Spinner,
    StyledProvider,
    VStack
} from '@gluestack-ui/themed';
import React, { useState } from 'react';

const STATUS_VALUES = [
    { label: "Todos", value: "todos" },
    { label: "Pago", value: "1" },
    { label: "Cancelado", value: "9" },
    { label: "Atrasado", value: "atrasado" },
    { label: "Pendente", value: "pendente" },
    { label: "Vence hoje", value: "hoje" },
    { label: "Aberto", value: "aberto" },
];

const MONTH_VALUES = [
    { label: "Todos", value: "todos" },
    { label: "Janeiro", value: "1" },
    { label: "Fevereiro", value: "2" },
    { label: "Março", value: "3" },
    { label: "Abril", value: "4" },
    { label: "Maio", value: "5" },
    { label: "Junho", value: "6" },
    { label: "Julho", value: "7" },
    { label: "Agosto", value: "8" },
    { label: "Setembro", value: "9" },
    { label: "Outubro", value: "10" },
    { label: "Novembro", value: "11" },
    { label: "Dezembro", value: "12" },
];

function generateYears() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 10; i <= currentYear + 1; i++) {
        years.push({ label: i.toString(), value: i.toString() });
    }
    return years;
}

let YEAR_VALUES = generateYears().reverse();

const handleClose = (setModalVisible) => {
    setModalVisible(false);
};

const FilterBoletosModal = ({
    isLoading,
    modalVisible,
    setModalVisible,
    preYear,
    preMonth,
    preStatus,
    preHasBankAccount,
    onSave
}) => {

    const [year, setYear] = useState(preYear)
    const [month, setMonth] = useState(preMonth)
    const [status, setStatus] = useState(preStatus)
    const [hasBankAccount, setHasBankAccount] = useState(preHasBankAccount)
    const [showSpinner, setShowSpinner] = useState(false);

    const handleSave = () => onSave({ year, month, status, hasBankAccount });

    return (
        <StyledProvider>
            <Box>
                <Modal
                    size="md"
                    isOpen={modalVisible}
                    onClose={() => {
                        setModalVisible(false);
                    }}
                    avoidKeyboard
                >
                    <ModalBackdrop />
                    <ModalContent>
                        <ModalHeader>
                            <HStack alignItems="center">
                                <Heading size="sm" fontWeight="$semibold">
                                    Filtro de boletos
                                </Heading>
                            </HStack>
                            <ModalCloseButton>
                                <Icon as={CloseIcon} sx={{ w: 16, h: 16 }} />
                            </ModalCloseButton>
                        </ModalHeader>
                        <ModalBody>
                            <VStack space="md">
                                <FormControl>
                                    <FormControlLabel>
                                        <FormControlLabelText>Status do boleto</FormControlLabelText>
                                    </FormControlLabel>
                                    <HStack space="sm">
                                        <Select defaultValue={status} w="100%" placeholder="Todos" onValueChange={e => setStatus(e)}>
                                            <SelectTrigger>
                                                <SelectInput />
                                                <SelectIcon as={ChevronDownIcon} mr="$3" />
                                            </SelectTrigger>
                                            <SelectPortal>
                                                <SelectBackdrop />
                                                <SelectContent>
                                                    <SelectDragIndicatorWrapper>
                                                        <SelectDragIndicator />
                                                    </SelectDragIndicatorWrapper>
                                                    {STATUS_VALUES.map((item, index) => (
                                                        <Select.Item
                                                            label={`${item.label}`}
                                                            value={`${item.value}`}
                                                            key={`${index}`}
                                                        />
                                                    ))}
                                                </SelectContent>
                                            </SelectPortal>
                                        </Select>
                                    </HStack>
                                </FormControl>
                                <FormControl>
                                    <FormControlLabel>
                                        <FormControlLabelText>Mês</FormControlLabelText>
                                    </FormControlLabel>
                                    <HStack space="sm">
                                        <Select defaultValue={month} w="100%" placeholder="Mês" onValueChange={e => setMonth(e)}>
                                            <SelectTrigger>
                                                <SelectInput />
                                                <SelectIcon as={ChevronDownIcon} mr="$3" />
                                            </SelectTrigger>
                                            <SelectPortal>
                                                <SelectBackdrop />
                                                <SelectContent>
                                                    <SelectDragIndicatorWrapper>
                                                        <SelectDragIndicator />
                                                    </SelectDragIndicatorWrapper>
                                                    {MONTH_VALUES.map((item, index) => (
                                                        <Select.Item
                                                            label={`${item.label}`}
                                                            value={`${item.value}`}
                                                            key={`${index}`}
                                                        />
                                                    ))}
                                                </SelectContent>
                                            </SelectPortal>
                                        </Select>
                                    </HStack>
                                </FormControl>
                                <FormControl>
                                    <FormControlLabel>
                                        <FormControlLabelText>Ano</FormControlLabelText>
                                    </FormControlLabel>
                                    <HStack space="sm">
                                        <Select defaultValue={year} w="100%" placeholder="Todos" onValueChange={e => setYear(e)}>
                                            <SelectTrigger>
                                                <SelectInput />
                                                <SelectIcon as={ChevronDownIcon} mr="$3" />
                                            </SelectTrigger>
                                            <SelectPortal>
                                                <SelectBackdrop />
                                                <SelectContent>
                                                    <SelectDragIndicatorWrapper>
                                                        <SelectDragIndicator />
                                                    </SelectDragIndicatorWrapper>
                                                    {YEAR_VALUES.map((item, index) => (
                                                        <Select.Item
                                                            label={`${item.label}`}
                                                            value={`${item.value}`}
                                                            key={`${index}`}
                                                        />
                                                    ))}
                                                </SelectContent>
                                            </SelectPortal>
                                        </Select>
                                    </HStack>
                                </FormControl>
                                <FormControl>
                                    <Checkbox
                                        value={hasBankAccount}
                                        size="md"
                                        onChange={() => setHasBankAccount(!hasBankAccount)}
                                    >
                                        <CheckboxIcon mr="$2">
                                            <CheckIcon />
                                        </CheckboxIcon>
                                        <CheckboxLabel>Somente emitidos pela empresa</CheckboxLabel>
                                    </Checkbox>
                                </FormControl>
                            </VStack>
                        </ModalBody>
                        <VStack space="sm">
                            <Box
                                sx={{
                                    padding: 20
                                }}
                                w="100%"
                            >
                                <Button
                                    action="secondary"
                                    variant="outline"
                                    isDisabled={isLoading}
                                    onPress={() => { setModalVisible(false); handleSave(); }}
                                >
                                    <ButtonText>Filtrar</ButtonText>
                                </Button>

                            </Box>
                        </VStack>
                    </ModalContent>
                </Modal>
            </Box>
        </StyledProvider>
    );
};

export default FilterBoletosModal;
