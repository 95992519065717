import { Platform } from 'react-native';

/**
 * Função para criar um URL temporário de uma imagem a partir de uma string Base64.
 * @param {string} base64String - A string base64 da imagem.
 * @returns {string} - Retorna o URL da imagem para exibição.
 */
function base64ToImage(base64String) {
  try {
    // Remover o prefixo 'data:image/png;base64,' se existir
    const base64Data = base64String.includes('base64,')
      ? base64String.split('base64,')[1]
      : base64String;

    if (Platform.OS === 'web') {
      // Para Expo Web
      const blob = new Blob([base64Data], { type: 'image/png' });
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      // Para React Native
      // Adicionar prefixo 'data:image/png;base64,' para criar uma URI de dados
      const uri = `data:image/png;base64,${base64Data}`;
      return uri;
    }
  } catch (error) {
    console.error('Erro ao converter Base64 para imagem:', error);
    return null;
  }
}

export default base64ToImage;
