import React, { useEffect, useState } from "react";
import { HStack, Spinner, Text, Image } from "@gluestack-ui/themed";
import ObterPixAsaas from "../helpers/ObterPixAsaas";
import base64ToImage from "../helpers/base64ToImage";

const GeneratePixQrcode = ({ qrcode, contaId, asaasId }) => {
    const [loading, setLoading] = useState(true);
    const [qrcodeImage, setQrcodeImage] = useState('');




    if (!asaasId) return (<>
        <HStack space="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 11 }}>
            <Image
                source={{
                    uri: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSh2denuZTfhKXWlE02YeVU0AlpTWPjo0q9rwUVPGzqTOfJjq_zTy5C4uSxk8zPhZnBdXM`,
                }}
                alt="qrcode"
                width="120px"
                height="120px"
            />
            <Text size="md">Fale com a Unitech</Text>
        </HStack>
    </>)
    console.info({ qrcode, contaId })
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (qrcode && qrcode !== "null" && qrcode !== null) {
                    setQrcodeImage(qrcode)
                    console.info('entrei aqui if')
                } else {
                    console.info('entrei aqui else')
                    const qr = await ObterPixAsaas(contaId);
                    console.info({ qr })
                    const basetoImage = base64ToImage(`data:image/jpeg;base64,${qr}`)
                    console.info({ basetoImage })
                    setQrcodeImage(`data:image/jpeg;base64,${qr.EncodedImage}`)
                }
            } catch (error) {
                console.info(error)
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            }

        })();
    }, [qrcode, contaId])

    return (<>
        {loading ?
            <>
                <HStack space="sm">
                    <Spinner size="large" />
                    <Text size="md">Carregando Pix</Text>
                </HStack>
            </>
            :
            <>
                <Image
                    source={{
                        uri: `${qrcodeImage}`,
                    }}
                    mr="5px"
                    alt="qrcode"
                    width="150px"
                    height="150px"
                />
            </>
        }
    </>)
};

export default GeneratePixQrcode;