import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import { useLocation, useParams } from "react-router-dom";

import {
  Badge,
  Box,
  Button,
  FlatList,
  HStack,
  Input,
  InputField,
  InputSlot,
  Pressable,
  Text
} from "@gluestack-ui/themed";

import {
  Filter,
  Search
} from "lucide-react-native";

import { BadgeText } from "@gluestack-ui/themed";
import FormatToBRL from "../helpers/FormatToBRL";
import GetMonth from "../helpers/GetMonth";

import useCompanyStorage from "../hooks/useCompanyStorage";
import useUniversalNavigation from "../hooks/useUniversalNavigation";
import urlConstruct from "../services/urlConstruct";

import { ButtonIcon, InputIcon, RefreshControl } from "@gluestack-ui/themed";
import FilterBoletosModal from "../components/FilterBoletosModal/FilterBoletosModal";
import LoadingPage from "../components/Loading/LoadingPage";
import ParseQueryParams from "../helpers/ParseQueryParams";
import api from "../services/api";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();
const isDateToday = (inputDate: string | number | Date) => new Date(inputDate).toDateString() === new Date().toDateString();
const isDatePast = (inputDate: string | number | Date) => new Date(inputDate) < new Date();
const isDatePending = (inputDate: string | number | Date) => {
  const monthFromNow = new Date(currentDate);
  monthFromNow.setDate(currentDate.getDate() + 30);
  return new Date(inputDate) > currentDate && new Date(inputDate) <= monthFromNow;
}

const BoletosPage = () => {

  const { navigate } = useUniversalNavigation();

  const { cnpj } = useParams();
  const location = useLocation();
  const { company } = useCompanyStorage();

  const [boletos, setBoletos] = useState([]);
  const [filteredBoletos, setFilteredBoletos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("todos");
  const [hasBankAccount, setHasBankAccount] = useState(false);
  const [month, setMonth] = useState(currentMonth.toString());
  const [year, setYear] = useState(currentYear.toString());
  const [modalVisible, setModalVisible] = useState(false);
  const [filterVencidas, setFilterVencidas] = useState(false);

  useEffect(() => {
    if (Platform.OS === "web") {
      document.body.style.overflow = "auto";
      document.body.style.height = "100%";
    }
  }, []);

  const fetchBoletos = async () => {

    if (!company) return;

    setLoading(true);

    try {

      let params = {
        IdEmpresa: company?.company?.idEmpresa,
        Busca: ''
      };

      if (cnpj) {
        params.Busca += `Contato.DocIdentificacao.Replace(".","").Replace("-","").Replace("/","")=="${cnpj}"`;
      }

      if (month) {
        if (month != "todos") {
          if (params.Busca) params.Busca += " && ";
          params.Busca += `DataVencimento.Month==${month}`;
        }
      }

      if (year) {
        if (year != "todos") {
          if (params.Busca) params.Busca += " && ";
          params.Busca += `DataVencimento.Year==${year}`;
        }
      }

      if (status !== "todos") {
        if (params.Busca) params.Busca += " && ";
        params.Busca += `Status == "${status === "hoje" || status === "pendente" || status === "atrasado" || status === "aberto" ? 0 : status}"`;
      }

      if (hasBankAccount) {
        if (params.Busca) params.Busca += " && ";
        params.Busca += "IdContaBancariaBoleto != null";
      }

      const { lista } = await api.get(urlConstruct('/api/ContaReceber', params));

      if (lista) {
        let filtered = lista;

        const agora = new Date();
        const dataLimite = new Date(agora.getTime() + 30 * 24 * 60 * 60 * 1000);

        filtered = filtered.filter(boleto => {
          const dataVencimento = new Date(boleto.dataVencimento);
          return dataVencimento <= dataLimite;
        });

        const monthFromNow = new Date(currentDate);
        monthFromNow.setDate(currentDate.getDate() + 30);

        if (status !== "todos") {
          filtered = filtered.filter(boleto => {
            const boletoDate = new Date(boleto.dataVencimento);
            switch (status) {
              case "hoje":
                return isDateToday(boletoDate);
              case "pendente":
                return boletoDate > currentDate && boletoDate <= monthFromNow;
              case "aberto":
                return boletoDate > currentDate;
              case "atrasado":
                return boletoDate < currentDate;
              default:
                return boleto.status.toString() === status;
            }
          });
        }

        if (month !== "todos") {
          filtered = filtered.filter(boleto => new Date(boleto.dataVencimento).getMonth() + 1 === parseInt(month));
        }

        if (year !== "todos") {
          filtered = filtered.filter(boleto => new Date(boleto.dataVencimento).getFullYear().toString() === year);
        }

        if (hasBankAccount) {
          filtered = filtered.filter(boleto => boleto.IdContaBancariaBoleto !== null);
        }

        setBoletos(filtered);
        setFilteredBoletos(filtered);
      } else {
        setBoletos([]);
        setFilteredBoletos([]);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParams: any = ParseQueryParams(location.search);

    if (queryParams?.status) {
      setStatus(queryParams.status)
    }
    if (queryParams?.month) {
      setMonth(queryParams.month)
    }
    if (queryParams?.year) {
      setYear(queryParams.year)
    }
    if (queryParams?.hasBankAccount) {
      setHasBankAccount(queryParams.hasBankAccount)
    }
    if (queryParams?.filter) {
      if (queryParams.filter === 'vencidas') {
        setFilterVencidas(true)
        setMonth('todos');
      }
    }

    fetchBoletos();
  }, [status, hasBankAccount, month, year, company, location.search]);

  const StatusText = (status: number, vencimento: string | number | Date) => {
    if (status === 1) return "Pago";
    if (status === 9) return "Cancelado";
    if (status === 0) {
      if (isDateToday(vencimento)) return "Vence hoje";
      if (isDatePast(vencimento)) return "Vencido";
      if (isDatePending(vencimento)) return "Pendente";
      return "Aberto";
    }
    return "Aberto";
  };

  const StatusBadge = ({ status, vencimento }) => {
    const text = StatusText(status, vencimento);

    const colorSchemes = {
      "Pago": "#dcfce7",
      "Pendente": "#fef9c3",
      "Vence hoje": "#ffb7b7",
      "Vencido": "#ffb7b7",
      "Cancelado": "#f4f4f5",
      "Aberto": "#dbeafe"
    };

    return (
      <Badge
        sx={{
          _dark: {
            backgroundColor: colorSchemes[text] || "#f4f4f5"
          },
          _light: {
            backgroundColor: colorSchemes[text] || "#f4f4f5"
          },
        }}>
        <BadgeText color="$black">{text}</BadgeText>
      </Badge>
    );
  };

  const createQueryParamsAndGoToNavigate = (params) => {

    navigate(`/b/detalhes?IdEmpresa=${company?.company?.idEmpresa}&&IdBoleto=${params.id}`);
  };


  const applyFilter = () => {
    setLoading(true);
    let filtered = boletos;
    if (searchTerm.length > 3) {
      filtered = filtered.filter(boleto =>
        boleto.contatoNome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (boleto.centroCustoDescricao && boleto.centroCustoDescricao.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    setFilteredBoletos(filtered);
    setLoading(false);
  };

  const onRefresh = async () => {
    setIsRefreshing(true);
    await fetchBoletos();
    setIsRefreshing(false);
  };

  const handleOpenModal = () => {
    setModalVisible(true)
  };

  const handleValuesFilter = (values) => {
    setFilterVencidas(false);
    setYear(values.year)
    setMonth(values.month)
    setStatus(values.status)
    setHasBankAccount(values.hasBankAccount)
  }
  const filtrarBoletosVencidos = (boletosArray) => {
    const dataAtual = Date.now();
    const boletosNaoPagos = boletos.filter(boleto => boleto.status === 0 && (boleto.idContaBancariaBoleto !== 'null' || boleto?.celcoinPixImagem !== "null"));
    const boletosVencidos = boletosNaoPagos.filter(boleto => Date.parse(boleto.dataVencimento) < dataAtual);
    return boletosVencidos;
  };

  if (loading) return (<LoadingPage color={'#01284b'} />);

  return (
    <>
      <FilterBoletosModal
        isLoading={isRefreshing || loading}
        onSave={handleValuesFilter}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        preYear={year}
        preMonth={month}
        preStatus={status}
        preHasBankAccount={hasBankAccount}
      />
      <Box
        flex={1}
        sx={{
          _light: { bg: "white" },
          _dark: { bg: "$backgroundDark950" },
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            marginTop: '-1px',
            backgroundColor: '#01284b',
            width: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <HStack
            sx={{
              width: '100%',
              maxWidth: 500,
              backgroundColor: '#01284b',
              paddingTop: 10,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10
            }}
            alignItems="center"
          >
            <Input
              value={searchTerm}
              onChange={text => {
                setSearchTerm(text);
                if (text.length > 3) {
                  applyFilter();
                } else {
                  setFilteredBoletos(boletos);
                }
              }}
              width='90%'
              maxWidth="100%"
              variant="outline"
              placeholderTextColor="white"
              sx={{
                _light: { bg: "white" },
                _dark: { bg: "$backgroundDark950" },
              }}
            >
              <InputSlot pl="$3">
                <InputIcon as={Search} />
              </InputSlot>
              <InputField py="$2" placeholder="Pesquisar por nome ou centro de custo..." />
            </Input>
            <Button
              sx={{
                marginLeft: 5,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 5,
                _light: { bg: "white" },
                _dark: { bg: "$backgroundDark950" },
              }}
              onPress={() => handleOpenModal()}
            >
              <ButtonIcon
                as={Filter}
                color="$textLight900"
                $dark-color="$textDark300"
              />
            </Button>
          </HStack>
        </Box>
        <Box
          flex={1}
          sx={{
            width: '100%',
            maxWidth: 500,
            overflow: 'scroll',
            flexGrow: 1
          }}
        >
          <Box
            flex={1} // Adicionando flex: 1 para ocupar todo o espaço disponível
            sx={{
              width: '100%',
              maxWidth: 500,
              maxHeight: '90%', // Defina uma altura máxima
              overflow: 'scroll',
            }}
          >
            <FlatList
              nestedScrollEnabled={true}
              scrollEventThrottle={16}
              data={filterVencidas ? filtrarBoletosVencidos(filteredBoletos) : filteredBoletos}
              refreshControl={
                <RefreshControl
                  refreshing={isRefreshing}
                  onRefresh={onRefresh}
                  title={isRefreshing ? 'Atualizando os boletos...' : 'Puxe para atualizar os boletos.'}
                />
              }
              renderItem={({ item: boleto }) => (
                <Box
                  sx={{
                    _light: { bg: "white" },
                    _dark: { bg: "$backgroundDark950" },
                  }}
                  $base-pl={0}
                  $base-pr={0}
                  $sm-pl="$4"
                  $sm-pr="$5"
                  py="$1"
                >
                  <Pressable
                    px={5}
                    mb="$1"
                    ml="$1"
                    mr="$1"
                    mt="$1"
                    borderRadius="$lg"
                    onPress={() => createQueryParamsAndGoToNavigate(boleto)}
                  >
                    <Box
                      sx={{
                        _light: { bg: "#ececec" },
                        _dark: { bg: "#252525" },
                        padding: 20
                      }}
                      rounded="$md"
                    >
                      <HStack
                        alignItems="center"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                        >
                          <StatusBadge
                            status={boleto.status}
                            vencimento={boleto.dataVencimento}
                          />
                          <GetMonth dateString={boleto.dataVencimento} />
                        </Box>
                        <Box
                          mb="$-4"
                          display="flex"
                          flexDirection="column"
                        >
                          <Text>R$ {FormatToBRL(boleto.valorTotal)}</Text>
                        </Box>
                      </HStack>
                      <Text mt="2">{boleto.contatoNome}</Text>
                    </Box>
                  </Pressable>
                </Box>
              )}
              keyExtractor={(item) => item.id}
            />
          </Box>
        </Box>
      </Box>

    </>
  );
};
export default BoletosPage;
