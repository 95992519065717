import React, { useContext, useEffect, useState } from 'react';
import { Image, Pressable, SafeAreaView, StyleSheet, Text, TextInput, View } from 'react-native';

import SearchSelect from '../components/SearchSelect';

import axios from 'axios';

import Constants from 'expo-constants';
import useCompanyStorage from '../hooks/useCompanyStorage';
import { ToastContext } from '../hooks/useToast';
import useUniversalNavigation from '../hooks/useUniversalNavigation';
import { useParams } from 'react-router-dom';

export default function LoginForm() {

  const toast = useContext(ToastContext);

  const { navigate } = useUniversalNavigation();
  const { saveCompany } = useCompanyStorage();
  const [loading, setLoading] = useState(false);
  const [hostsArray, setHostsArray] = useState([]);
  const [host, setHost] = useState(null);
  const [cnpj, setCNPJ] = useState('');

  const { tenantId } = useParams();


  useEffect(() => {
    const findInList = hostsArray.findIndex(h => h.key === tenantId);
    if (findInList != -1) {
      setHost(tenantId)
    }
  }, [hostsArray]);

  const handleButtonPress = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.gestor.unitechsistemas.com/${host}/api/Contato/getByDocIdentificacao?docIdentificacao=${cnpj.replace(/\D/g, '')}`);
      if (!response?.data) {
        toast.error({ title: 'Erro ao buscar empresas.', description: `O CNPJ "${cnpj}" não foi encontrado` });
      } else {
        await saveCompany({ tenantId: host, cnpj: cnpj.replace(/\D/g, ''), company: response.data });
        setTimeout(() => { navigate('/') }, 500);
      }
    } catch (error) {
      console.error(error)
      toast.error({ title: 'Erro ao buscar empresas.', description: `O CNPJ "${cnpj}" não foi encontrado` });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://api.gestor.unitechsistemas.com/financeiro/api/Tentants`);
        const data = response.data;
        const filtered = data.map((t: any) => ({ value: t.host, key: t.host }));
        setHostsArray(filtered);
      } catch (err) {
        console.error("Error fetching tenants:", err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const formatCNPJ = (input) => {
    let cleaned = input.replace(/\D/g, '');
    cleaned = cleaned.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    return cleaned;
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.containerProps} >
        <Image
          source={require("../../assets/logo.png")}
          style={styles.image}
          alt="logo"
          resizeMode='contain'
        />
        <Text style={styles.title}>Login</Text>
        <View style={styles.inputView}>
          {host === tenantId ?
            <Text style={styles.subtitle}>{tenantId}</Text> :
            (
              <SearchSelect
                onSelect={setHost}
                data={hostsArray}
                value={host}
                placeholder="Selecione o sistema"
                searchPlaceholder="Buscar"
                notFoundText="Sistema não encontrado"
              />
            )}
          <TextInput style={styles.input}
            placeholder='CNPJ'
            value={cnpj}
            onChangeText={(text) => setCNPJ(formatCNPJ(text))}
            autoCorrect={false}
            maxLength={18}
            keyboardType='numeric'
            autoCapitalize='none'
          />
        </View>
        <View style={styles.buttonView}>
          <Pressable style={styles.button} onPress={() => { handleButtonPress() }}>
            <Text style={styles.buttonText}>Entrar</Text>
          </Pressable>
        </View>
        <Text style={styles.footerText}>@Unitech Sistemas</Text>
      </View>
    </SafeAreaView>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: 'center',
    paddingTop: Constants.statusBarHeight + 20,
    backgroundColor: "#f0f0f0",
  },
  containerProps: {
    width: "80%",
    maxWidth: "500px",
    alignItems: "center",
  },
  image: {
    height: 160,
    width: 170
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    paddingVertical: 40,
    color: "#01284b"
  },
  subtitle: {
    fontSize: 30,
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    paddingVertical: 5,
    color: "#01284b"
  },
  inputView: {
    gap: 15,
    width: "100%",
    paddingHorizontal: 40,
    marginBottom: 5
  },
  input: {
    height: 40,
    paddingHorizontal: 20,
    borderColor: "#01284b",
    borderWidth: 1,
    borderRadius: 7
  },
  rememberView: {
    width: "100%",
    paddingHorizontal: 50,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 8
  },
  switch: {
    flexDirection: "row",
    gap: 1,
    justifyContent: "center",
    alignItems: "center"

  },
  rememberText: {
    fontSize: 13
  },
  forgetText: {
    fontSize: 11,
    color: "#01284b"
  },
  button: {
    backgroundColor: "#01284b",
    height: 45,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center"
  },
  buttonText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold"
  },
  buttonView: {
    width: "100%",
    paddingHorizontal: 40,
  },
  optionsText: {
    textAlign: "center",
    paddingVertical: 10,
    color: "gray",
    fontSize: 13,
    marginBottom: 6
  },
  mediaIcons: {
    flexDirection: "row",
    gap: 15,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 23
  },
  icons: {
    width: 40,
    height: 40,
  },
  footerText: {
    marginTop: 5,
    textAlign: "center",
    color: "gray",
  },
  signup: {
    color: "#01284b",
    fontSize: 13
  }
})