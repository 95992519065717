import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  ButtonText,
  Center,
  HStack,
  Pressable,
  Text,
  VStack
} from '@gluestack-ui/themed';

import {
  CircleCheckBig,
  OctagonAlert,
  TriangleAlert
} from "lucide-react-native";

import axios from 'axios';
import * as Clipboard from 'expo-clipboard';
import { Linking } from "react-native";
import { useParams } from "react-router-dom";
import ScrollableLayout from '../../context/ScrollableLayout';
import useCompanyStorage from '../../hooks/useCompanyStorage';
import { ToastContext } from '../../hooks/useToast';
import useUniversalNavigation from '../../hooks/useUniversalNavigation';
import api from '../../services/api';
import urlConstruct from '../../services/urlConstruct';
import LoadingPage from '../Loading/LoadingPage';

import AsyncStorage from '@react-native-async-storage/async-storage';

const getCurrentDateBrazil = async () => {
  try {
    const response = await fetch("http://worldtimeapi.org/api/timezone/America/Sao_Paulo");
    const data = await response.json();
    return new Date(data.datetime);
  } catch (error) {
    return new Date();
  }
}

const calculateHistoryCounts = (boletos, dataAtual) => {

  const agora = new Date();
  const dataLimite = new Date(agora.getTime() + 30 * 24 * 60 * 60 * 1000);
  const abertos = boletos.filter(item => {
    const dataVencimento = new Date(item.dataVencimento);
    return dataVencimento > agora && dataVencimento <= dataLimite;
  }).length;

  const pagos = boletos.filter(item => new Date(item.dataVencimento).toDateString() === dataAtual.toDateString()).length || 0;
  const fechados = boletos.filter(item => new Date(item.dataVencimento) < dataAtual && item.status !== 9).length || 0;
  const cancelados = boletos.filter(item => item.status === 9).length || 0;

  return { abertos, pagos, fechados, cancelados };
}

const verificaLista = (lista) => {
  const agora = new Date();
  const trintaDiasAtras = new Date(agora.setDate(agora.getDate() - 30));

  const formatarData = (data) => {
    const ano = data.getFullYear();
    const mes = data.getMonth() + 1;
    const dia = data.getDate();
    return `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}T00:00:00`;
  };

  const dataLimite = formatarData(trintaDiasAtras);

  return lista.some(obj => {
    const dataObj = new Date(obj.dataVencimento);
    const dataLimiteObj = new Date(dataLimite);
    return obj.status === 0 && dataObj >= dataLimiteObj && dataObj <= new Date();
  });
};


const PressableCard = ({ title, navigate, ...props }) => {
  return (
    <Pressable
      w="48%"
      bg="white"
      py={5}
      px={3}
      borderRadius="$lg"
      onPress={navigate}
      {...props}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}>
        <Text textAlign="center" fontWeight="bold">{title}</Text>
      </Box>
    </Pressable>
  );
};

const HomeContent = ({ data }) => {

  const { navigate } = useUniversalNavigation();
  const { tenantId, cnpj } = useParams();
  const toast = useContext(ToastContext);
  const [company, setCompany] = useState(null);
  const [pendingPayments, setPendingPayments] = useState(false);
  const [loading, setLoading] = useState(true);
  const [boletos, setBoletos] = useState([]);
  const [historyCounts, setHistoryCounts] = useState({ abertos: 0, pagos: 0, fechados: 0, cancelados: 0 });
  const { saveCompany, removeCompany } = useCompanyStorage();

  const createQueryParamsAndGoToNavigate = (params) => {
    console.info(params)
    navigate(`/b/detalhes?IdEmpresa=${params.idEmpresa}&&IdBoleto=${params.id}`)
  }

  useEffect(() => {
    if (data?.company) {
      setCompany(data?.company);
    } else {
      (async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://api.gestor.unitechsistemas.com/${tenantId}/api/Contato/getByDocIdentificacao?docIdentificacao=${cnpj}`);
          if (response.status != 200) {
            removeCompany();
          } else {
            await saveCompany({
              tenantId: tenantId,
              cnpj: cnpj.replace(/\D/g, ''),
              company: response.data
            });
            setCompany(response.data);
          };
        } catch (err) {
          console.error("Error fetching tenants:", err);
        } finally {
          setLoading(false);
        }
      })();

    }
  }, [data]);

  useEffect(() => {
    (async () => {
      if (company) {
        setLoading(true);
        try {
          const dataAtual = await getCurrentDateBrazil();
          const { lista } = await api.get(urlConstruct('/api/ContaReceber', {
            Busca: `Contato.DocIdentificacao.Replace(".","").Replace("-","").Replace("/","")=="${cnpj}"`,
            IdEmpresa: company.idEmpresa
          }));
          if (lista.length > 0) {
            setBoletos(lista);
            setPendingPayments(verificaLista(lista));
            const counts = calculateHistoryCounts(lista, dataAtual);
            setHistoryCounts(counts);
          }
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false);
        }

      }
    })();
  }, [company]);

  const reloadComponent = () => console.info('Recarregando');

  if (loading) return (<LoadingPage />);

  const dataAtual = Date.now();
  const boletosNaoPagos = boletos.filter(boleto => boleto.status === 0 && (boleto.idContaBancariaBoleto !== 'null' || boleto?.celcoinPixImagem !== "null"));

  const boletosVencidos = boletosNaoPagos.filter(boleto => Date.parse(boleto.dataVencimento) < dataAtual);

  const boletosFuturos = boletosNaoPagos.filter(boleto => Date.parse(boleto.dataVencimento) > dataAtual);

  const boletoMaisProximo = boletosFuturos.reduce((boletoMaisProximo, boleto) => {
    const dataVencimentoAtual = Date.parse(boleto.dataVencimento);
    const diferenca = dataVencimentoAtual - dataAtual;
    const menorDiferenca = Date.parse(boletoMaisProximo.dataVencimento) - dataAtual;
    return diferenca < menorDiferenca ? boleto : boletoMaisProximo;
  }, boletosFuturos[0] || null); // Inicia com o primeiro boleto futuro ou null se não houver

  function getCodeFromHtml(htmlString) {
    const startTag = '<td class="w450 Ar ld" valign="bottom">';
    const endTag = '</td>';
    let start = htmlString.indexOf(startTag);
    if (start === -1) return null;
    start += startTag.length;
    const end = htmlString.indexOf(endTag, start);
    if (end === -1) return null;
    let code = htmlString.slice(start, end);
    code = code.replace(/[^a-zA-Z0-9]/g, ' ');
    return code;
  }

  async function generateFile(fileData) {
    try {
      const getCompany = await AsyncStorage.getItem('company');
      const { tenantId } = JSON.parse(getCompany);

      const fileUrl = `https://gestor.unitechsistemas.com/${tenantId}/${fileData.diretorio}/${fileData.nome}${fileData.extensao}`;

      // Abre o link no navegador padrão do dispositivo
      await Linking.openURL(fileUrl);
    } catch (error) {
      console.error('Erro ao abrir o arquivo no navegador:', error);
    }
  };

  const copiarDigitavel = async (IdBoleto, IdEmpresa) => {
    const data = await api.post(`/api/ContaReceber/gerarBoletoApp/${IdBoleto}?idEmpresa=${IdEmpresa}`);
    const codigoDoBoleto = getCodeFromHtml(data);
    await Clipboard.setStringAsync(codigoDoBoleto);
  }

  return (
    <Box
      sx={{
        _light: { bg: "white" },
        _dark: { bg: "$backgroundDark950" },
      }}
    >
      <ScrollableLayout onRefresh={reloadComponent} textOnRefresh="Puxe para atualizar">
        {(!!boletoMaisProximo || boletosVencidos.length === 0) && (
          <Center alignItems="center" sx={{
            _light: { bg: "white" },
            _dark: { bg: "$backgroundDark950" },
          }}>
            <VStack
              space={'lg'}
              mt={5}
              softShadow="1"
              sx={{
                borderRadius: 15,
                padding: 20,
                width: '100%',
                maxWidth: 550,
                _light: { bg: "#ebeef1" },
                _dark: { bg: "#252525" },
              }}
            >
              <Box
                sx={{
                  _light: { bg: "#ebeef1", borderColor: "$borderLight100" },
                  _dark: { bg: "#252525", borderColor: "#252525" }
                }}
                rounded="$lg"
                p={5}
                alignItems="center"
              >
                {boletoMaisProximo ? (
                  <>
                    <Box sx={{ mb: 15 }}>
                      <TriangleAlert strokeWidth={3} color={'#e3c300'} />
                    </Box>
                    <Text fontSize={20} fontWeight="bold" color={'#e3c300'} mb={2}>
                      Sua próxima fatura já está disponivel!
                    </Text>
                    <Text textAlign="center" mb={4}>
                      Você pode antecipar o pagamento da sua fatura e manter suas contas em dia.
                    </Text>
                    <Pressable
                      onPress={() => createQueryParamsAndGoToNavigate(boletoMaisProximo)}
                      rounded="$xs"
                      bg="#e3c300"
                      py="$1"
                      px="$3"
                    >
                      <ButtonText
                        textTransform="uppercase"
                        fontSize="$sm"
                        fontWeight="$bold"
                        color="$white"
                      >
                        Acessar fatura
                      </ButtonText>
                    </Pressable>
                  </>
                ) : (
                  <>
                    <Box sx={{ mb: 15 }}>
                      <CircleCheckBig strokeWidth={3} color={'#22c55e'} />
                    </Box>
                    <Text fontSize={20} fontWeight="bold" color={'#22c55e'} mb={2}>
                      Tudo está pago!
                    </Text>
                    <Text textAlign="center" mb={4}>
                      Você não tem contas pendentes para este mês.
                    </Text>
                  </>
                )}
              </Box>
            </VStack>
          </Center>
        )}
        {!!boletoMaisProximo && (
          <Center alignItems="center" sx={{
            _light: { bg: "white" },
            _dark: { bg: "$backgroundDark950" },
          }}>
            <VStack
              mt={2}
              softShadow="1"
              sx={{
                justifyContent: 'space-around',
                width: '100%',
                maxWidth: 550
              }}
            >
              <HStack sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }} reversed={false}>
                {boletoMaisProximo.arquivoAnexo !== null && <PressableCard
                  w='25%'
                  title="Baixar Nota"
                  sx={{
                    margin: '5px',
                    width: "30%",
                    height: '60px',
                    _light: { bg: "#01284b14" },
                    _dark: { bg: "#252525" },
                  }}
                  navigate={() => {
                    generateFile(boletoMaisProximo.arquivoAnexo)
                  }} />}
                {boletoMaisProximo.celcoinPixCopiaCola !== null && <PressableCard
                  w='25%'
                  title="Copiar PIX"
                  sx={{
                    margin: '5px',
                    width: "30%",
                    height: '60px',
                    _light: { bg: "#01284b14" },
                    _dark: { bg: "#252525" },
                  }}
                  navigate={() => {
                    Clipboard.setStringAsync(boletoMaisProximo.celcoinPixCopiaCola);
                    toast.success({
                      title: 'Sucesso!',
                      description: 'Código do boleto copiado para a área de transferência.'
                    });
                  }} />
                }
                {/*
                 <PressableCard
                  w='25%'
                  title="Ver boleto"
                  sx={{
                    margin: '5px',
                    width: boletoMaisProximo.celcoinPixCopiaCola === null && boletoMaisProximo.arquivoAnexo === null ? "48%" : "30%",
                    height: '60px',
                    _light: { bg: "#01284b14" },
                    _dark: { bg: "#252525" },
                  }}
                  navigate={() => { Linking.openURL(boletoMaisProximo.celcoinLinkPDF); }} />
               */}
                {console.info({ boletoMaisProximo })}
                <PressableCard
                  w='25%'
                  title="Copiar linha digitável"
                  sx={{
                    margin: '5px',
                    width: boletoMaisProximo.celcoinPixCopiaCola === null && boletoMaisProximo.arquivoAnexo === null ? "48%" : "30%",
                    height: '60px',
                    _light: { bg: "#01284b14" },
                    _dark: { bg: "#252525" },
                  }}
                  navigate={async () => {
                    if (boletoMaisProximo?.idContaBancariaBoleto !== null && boletoMaisProximo.celcoinLinhaDigitavel === null) {
                      await copiarDigitavel(boletoMaisProximo.id, boletoMaisProximo.idEmpresa)
                      toast.success({
                        title: 'Sucesso!',
                        description: 'Código do boleto copiado para a área de transferência.'
                      });
                    } else if (boletoMaisProximo.celcoinLinhaDigitavel !== null) {
                      Clipboard.setStringAsync(boletoMaisProximo.celcoinLinhaDigitavel);
                      toast.success({
                        title: 'Sucesso!',
                        description: 'Código do boleto copiado para a área de transferência.'
                      });
                    }

                  }}
                />
              </HStack>
            </VStack>
          </Center>
        )}
        {boletosVencidos.length > 0 && (
          <Center flex={1} alignItems="center" sx={{
            _light: { bg: "white" },
            _dark: { bg: "$backgroundDark950" },
          }}>
            <VStack
              space={'lg'}
              mt={5}
              softShadow="1"
              sx={{
                borderRadius: 15,
                padding: 20,
                width: '100%',
                maxWidth: 550,
                _light: { bg: "#ebeef1" },
                _dark: { bg: "#252525" },
              }}
            >
              <Box
                sx={{
                  _light: { bg: "#ebeef1", borderColor: "$borderLight100" },
                  _dark: { bg: "#252525", borderColor: "#252525" }
                }}
                rounded="$lg"
                p={5}
                alignItems="center"
              >
                <>
                  <Box sx={{ mb: 15 }}>
                    <OctagonAlert strokeWidth={3} color={'#ff0000'} />
                  </Box>
                  <Text fontSize={20} fontWeight="bold" color={'#ff0000'} mb={2}>
                    Você tem faturas vencidas!
                  </Text>
                  <Text textAlign="center" mb={4}>
                    Mantenha seus pagamentos em dia para evitar juros e manter um bom histórico de crédito.
                  </Text>
                  <Pressable
                    onPress={() => navigate('/boletos?filter=vencidas')}
                    rounded="$xs"
                    bg="#ff0000"
                    py="$1"
                    px="$3"
                  >
                    <ButtonText
                      textTransform="uppercase"
                      fontSize="$sm"
                      fontWeight="$bold"
                      color="$white"
                    >
                      Ver vencidas
                    </ButtonText>
                  </Pressable>
                </>
              </Box>
            </VStack>
          </Center>
        )}
      </ScrollableLayout>
    </Box>
  );
};

export default HomeContent;
