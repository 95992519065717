import React, { useEffect, useState } from "react";
import { Platform, StatusBar } from "react-native";
import { useParams, useLocation } from "react-router-dom";
import { Box } from "@gluestack-ui/themed";
import {
    DollarSign,
    Home,
    Info
} from "lucide-react-native";
import useUniversalNavigation from "../hooks/useUniversalNavigation";
import MobileBottomTabs from "../components/MobileBottomTabs";
import MobileProfilePage from "../components/MobileProfilePage";
import MobileModeChangeButton from "../components/MobileModeChangeButton";
import Header from "../components/Header";
import axios from "axios";
import useCompanyStorage from "../hooks/useCompanyStorage";

const Layout = ({ children }) => {

    const { navigate } = useUniversalNavigation();
    const { tenantId, cnpj } = useParams();
    const location = useLocation();
    const { saveCompany, removeCompany, setCompany } = useCompanyStorage();

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`https://api.gestor.unitechsistemas.com/${tenantId}/api/Contato/getByDocIdentificacao?docIdentificacao=${cnpj}`);

                if (response.status != 200) {
                    removeCompany();
                } else {
                    await saveCompany({
                        tenantId: tenantId,
                        cnpj: cnpj.replace(/\D/g, ''),
                        company: response.data
                    });
                    setCompany(response.data);
                };

            } catch (err) {
                console.error("Error fetching tenants:", err);
            }
        })()

    }, [tenantId, cnpj])

    const [activeTab, setActiveTab] = useState("Inicio");
    const [useHeader, setUseHeader] = useState(true);

    console.info({ pathname: location.pathname });

    useEffect(() => {
        console.log("Current pathname:", location.pathname); // Verificar o valor do pathname

        switch (true) {
            case location.pathname.includes("/boletos_pix"):
                setUseHeader(false);
                break;
            case location.pathname.includes("/boletos"):
                setActiveTab('Boletos');
                setUseHeader(true);
                break;
            case location.pathname.includes("/detalhes"):
                setActiveTab('Detalhes');
                setUseHeader(true);
                break;
            default:
                setActiveTab('Inicio');
                setUseHeader(true);
                break;
        }
    }, [location.pathname]);




    const bottomTabs = [
        {
            icon: Home,
            label: "Inicio",
            name: 'home',
            router: '',
            params: { tenantId, cnpj }
        },

        {
            icon: DollarSign,
            label: "Boletos",
            name: 'boleto',
            router: `/boletos`,
            params: { tenantId, cnpj }
        },
        {
            icon: Info,
            label: "Sobre",
            name: 'info',
            router: "/info",
            params: { tenantId, cnpj },
            disabled: true,
        }
    ];

    useEffect(() => {
        if (Platform.OS === "web") {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
        }
    }, []);

    StatusBar.setBarStyle('light-content');

    return (
        <React.Fragment>
            <>
                <Header useHeader={useHeader} />
                <MobileProfilePage isActive={activeTab === "Profile"} />
                <>
                    {children ? children : null}
                    {useHeader && (
                        <MobileModeChangeButton />
                    )}
                </>
                {useHeader && (
                    <MobileBottomTabs
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        bottomTabs={bottomTabs}
                        onClickNavigate={navigate}
                    />
                )}
            </>
        </React.Fragment>
    );
};

export default Layout;