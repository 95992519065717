import React from "react";

import { LogBox } from 'react-native';

import { GluestackUIProvider } from "@gluestack-ui/themed";
import { SafeAreaView, StyleSheet } from "react-native";
import { config } from "./gluestack-ui.config";

import {
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_900Black,
} from "@expo-google-fonts/inter";
import { useFonts } from "expo-font";
import { ToastProvider } from "./src/hooks/useToast";
import AppRoutes from "./src/routes/AppRoutes";
import { ThemeContext } from "./src/context/ThemeContext";
import "./styles";

export default function App() {
  const [colorMode, setColorMode] = React.useState<"dark" | "light">("light");

  const [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_900Black,
  });

  LogBox.ignoreAllLogs();

  if (!fontsLoaded) {
    return null;
  }

  const toggleColorMode = async () => {
    setColorMode((prev) => (prev === "light" ? "dark" : "light"));
  };


  return (
    <>
      {/* top SafeAreaView */}
      <SafeAreaView
        style={{
          backgroundColor: colorMode === "light" ? "#01284b" : "#01284b",
        }}
      />
      {/* bottom SafeAreaView */}
      <SafeAreaView
        style={{
          ...styles.container,
          backgroundColor: colorMode === "light" ? "#01284b" : "#01284b",
        }}
      >
        <GluestackUIProvider config={config} colorMode={colorMode}>
          <ThemeContext.Provider
            value={{
              colorMode,
              toggleColorMode
            }}>
            <ToastProvider>
              <AppRoutes />
            </ToastProvider>
          </ThemeContext.Provider>
        </GluestackUIProvider>
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
