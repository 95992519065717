import { HStack, Pressable, Text } from "@gluestack-ui/themed";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import useUniversalNavigation from "../../hooks/useUniversalNavigation";

const HeaderTabs = () => {

  const { navigate } = useUniversalNavigation();

  const [selectedTab, setSelectedTab] = useState("Inicio");

  const { tenantId, cnpj } = useParams();

  const location = useLocation();

  const MenuList = [
    {
      label: "Inicio",
      router: '',
    },
    {
      label: "Boletos",
      router: `/boletos`,
    },
    {
      label: "Sobre",
      router: "/info",
      disabled: true
    }
  ];

  useEffect(() => {
    if (location.pathname.includes("/boletos")) {
      setSelectedTab('Boletos')
    } else if (location.pathname.includes("/detalhes")) {
      setSelectedTab('Detalhes')
    } else {
      setSelectedTab('Inicio')
    }
  }, [location.pathname]);


  const handleSelectRouter = (menu: any) => {
    setSelectedTab(menu.label);
    navigate(menu.router);
  }


  return (
    <HStack h="$20" sx={{ paddingLeft: 10 }} alignItems="center" justifyContent="space-between">
      <HStack
        p="$3"
        alignItems="center"
        borderColor="$borderLight200"
        sx={{ _dark: { borderColor: "$borderDark900" } }}
      >
        {MenuList.map(menu => (
          <Pressable
            rounded="$full"
            disabled={menu?.disabled}
            bg={
              selectedTab === menu.label ? "#fff" : "transparent"
            }
            onPress={() => handleSelectRouter(menu)}
            px="$3"
            py="$1.5"
          >
            <Text size="sm"
              sx={{
                _light: {
                  color: menu.disabled ? "#787878" : selectedTab === menu.label ? "#01294c" : "#fff"
                },
                _dark: {
                  color: menu.disabled ? "#787878" : selectedTab === menu.label ? "#01294c" : "#fff"
                }
              }}
              fontWeight="$medium"
            >
              {menu.label}
            </Text>
          </Pressable>
        ))}
      </HStack>
    </HStack >
  );
};

export default HeaderTabs;
