import React from "react";
import { Alert, VStack, HStack, Box, Heading, AlertIcon, Text } from "@gluestack-ui/themed";
import { Info } from "lucide-react-native";

const AlertInfo = ({ title, description }) => {
    return (
        <Alert maxW="400" w="100%" action="info" variant="solid">
            <VStack space={'sm'} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={'sm'} alignItems="center" justifyContent="space-between">
                    <HStack space={2} flexShrink={1} alignItems="center">
                        <AlertIcon as={Info} mr="$3" />
                        <Heading sx={{ fontSize: '20px' }} _dark={{ _text: { color: "coolGray.600" } }}>
                            {title}
                        </Heading>
                    </HStack>
                </HStack>
                <Box pl="6" _dark={{ _text: { color: "coolGray.600" } }}>
                    <Text>{description}</Text>
                </Box>
            </VStack>
        </Alert>
    )
}

export default AlertInfo;