import React, { useState, useCallback } from "react";
import { ScrollView, RefreshControl } from 'react-native';

export default function ScrollableLayout({ children, onRefresh, textOnRefresh }) {
    const [refreshing, setRefreshing] = useState(false);

    const handleRefresh = useCallback(async () => {
        setRefreshing(true);

        if (onRefresh) {
            await onRefresh();
        }

        setRefreshing(false);
    }, [onRefresh]);

    return (
        <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1 }}
            refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={handleRefresh}
                    tintColor="#000"
                    title={textOnRefresh}
                    titleColor="#000"
                />
            }
        >
            {children}
        </ScrollView>
    );
}
