import { VStack } from "@gluestack-ui/themed";
import React from "react";
import { ScrollView } from "react-native";
import MenuItemsSection from "./sidebar/MenuItemsSection";

const Sidebar = () => {
  return (
    <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
      <VStack space="xl" py="$6" px="$4">
        <VStack space="xl" px="$2">
          <MenuItemsSection />
        </VStack>
      </VStack>
    </ScrollView>
  );
};
export default Sidebar;
