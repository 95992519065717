import { Text } from "@gluestack-ui/themed";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function GetMonth({ dateString }) {
    const data = new Date(dateString);
    const anoAtual = new Date().getFullYear();
    const anoData = data.getFullYear();

    const dataFormatada = anoData === anoAtual
        ? format(data, 'MMMM', { locale: ptBR })
        : format(data, 'MMMM / yyyy', { locale: ptBR });

    return <Text sx={{ paddingLeft: 10 }}>{dataFormatada}</Text>;
}
